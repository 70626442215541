import React, { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ConfirmationStatus } from "./ConfirmationStatus";
import {
  useGetPatientConfirmationByIdQuery,
  useSetPatientConfirmationByIdMutation,
} from "../../services/confirmationApi";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const AppointmentConfirmation = () => {
  const [setPatientConfirmationById] = useSetPatientConfirmationByIdMutation();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      setPatientConfirmationById(id);
    }
  }, [id]);

  const { data, isError, isFetching } = useGetPatientConfirmationByIdQuery(id, {
    skip: !id,
  });

  const practiceInformation = data?.practice || {
    address: null,
    city: null,
    state: null,
    zip: null,
    name: null,
    email: null,
    web: null,
    phone: null,
  };

  const { address, city, state, zip, name, email, web, phone } =
    practiceInformation;
  const { upcomingAppointmentExists } = data || {
    upcomingAppointmentExists: false,
  };
  return (
    <>
      <Header name={name} email={email} website={web} phone={phone} />
      {isFetching && <CircularProgress color="primary" />}
      {!isFetching && (
        <ConfirmationStatus
          upcomingAppointmentExists={upcomingAppointmentExists && !isError}
        />
      )}
      <Footer address={address} city={city} state={state} zip={zip} />
    </>
  );
};
