import React from "react";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";

import { LIGHT_THEME } from "./themes/lightTheme";
import { AppointmentConfirmation } from "./features/appointmentConfirmation/AppointmentConfirmation";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {
  const createdTheme = createTheme(LIGHT_THEME);
  const responsiveTheme = responsiveFontSizes(createdTheme);
  const router = createBrowserRouter([
    {
      path: "/:id",
      element: <AppointmentConfirmation />,
    },
  ]);
  return (
    <ThemeProvider theme={responsiveTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
