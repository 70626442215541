import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as AppointmentSuccess } from "../../assets/appointment_success.svg";
import { ReactComponent as LinkExpired } from "../../assets/link_expired.svg";

interface ConfirmationStatusProps {
  upcomingAppointmentExists: boolean;
}

export const ConfirmationStatus: React.FC<ConfirmationStatusProps> = ({
  upcomingAppointmentExists,
}) => {
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      p={8}
    >
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="main"
          alignItems="center"
          justifyContent="space-around"
          sx={{
            flexDirection: { xs: "column-reverse", sm: "row" },
            display: "flex",
          }}
        >
          <Stack>
            {upcomingAppointmentExists && (
              <Box alignItems="center" justifyContent="center">
                <Typography variant="h2">You&apos;re All Set</Typography>
                <Typography mt={3}>
                  You&apos;ve been confirmed for your appointment.
                </Typography>
                <Typography mt={1}>
                  If you have any questions regarding your appointment, please
                  give us a call.
                </Typography>
              </Box>
            )}
            {!upcomingAppointmentExists && (
              <Box alignItems="center" justifyContent="center">
                <Typography variant="h2">Appointment not found</Typography>
                <Typography mt={3}>
                  Please call us ASAP to review your next appointment.
                </Typography>
              </Box>
            )}
          </Stack>
          <Stack>
            <Box alignItems="center" justifyContent="center">
              {upcomingAppointmentExists && (
                <AppointmentSuccess width={218} height={206} />
              )}
              {!upcomingAppointmentExists && (
                <LinkExpired width={218} height={206} />
              )}
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
