import React from "react";
import { Button, Drawer, Link, Stack, styled, Typography } from "@mui/material";
import { ReactComponent as CallUsIcon } from "../../assets/icons/call_us.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/icons/website.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import drawer_background from "../../assets/drawer_background.png";
import { ReactComponent as YapiLogoLight } from "../../assets/footer_yapi_logo_light.svg";

export const SLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  border: "solid 1px",
  display: "flex",
  flexDirection: "row",
  height: "37px",
  borderRadius: "24px",
  alignItems: "center",
  gap: "10px",
  width: "90px",
  justifyContent: "center",
  paddingRight: "6px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: `rgba(0, 0, 0, 0)`,
    borderColor: theme.palette.primary.main,
  },
}));

const SDrawer = styled(Drawer)(() => ({
  "& .MuiDrawer-paper": {
    backgroundImage: `url(${drawer_background})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
  },
}));

const SStack = styled(Stack)(() => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  justifyContent: "center",
  marginBottom: "50px",
}));

const DrawerFooter = () => {
  return (
    <SStack direction="row">
      <Typography lineHeight="37px" sx={{ paddingRight: "5px" }}>
        Powered by
      </Typography>
      <YapiLogoLight />
    </SStack>
  );
};
const DrawerHeader = ({ closeDrawer }) => {
  return (
    <Stack direction="column">
      <Stack direction="column" sx={{ width: "100%", alignItems: "end" }}>
        <Button onClick={closeDrawer}>
          <CloseIcon />
        </Button>
      </Stack>
      <Stack mt={4} direction="column" sx={{ alignItems: "center" }}>
        <Typography variant="h4">Need Help?</Typography>
        <Typography mt="8px" variant="caption" color="text.secondary">
          Here&apos;s Our Info.
        </Typography>
      </Stack>
    </Stack>
  );
};

export const MobileDrawer = ({
  phone,
  email,
  websiteUrl,
  isOpen,
  closeDrawer,
}) => {
  return (
    <SDrawer anchor="right" open={isOpen} onClose={closeDrawer}>
      <DrawerHeader closeDrawer={closeDrawer} />
      <Stack mt={2} mx="50px" spacing={2}>
        {phone && (
          <SLink
            href={`tel:${phone}`}
            color="text.primary"
            underline="none"
            pl={1}
          >
            <CallUsIcon />
            <Typography variant="h6" fontSize="0.85rem">
              Call Us
            </Typography>
          </SLink>
        )}

        {email && (
          <SLink
            href={`mailto:${email}`}
            color="text.primary"
            underline="none"
            pl={1}
          >
            <EmailIcon />
            <Typography variant="h6" fontSize="0.85rem">
              Email Us
            </Typography>
          </SLink>
        )}

        {websiteUrl && (
          <SLink
            href={websiteUrl}
            target="_blank"
            rel="noreferrer"
            color="text.primary"
            underline="none"
            pl={1}
          >
            <WebsiteIcon />
            <Typography variant="h6" fontSize="0.85rem">
              Visit Us
            </Typography>
          </SLink>
        )}
      </Stack>
      <DrawerFooter />
    </SDrawer>
  );
};
