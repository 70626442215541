import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface IPracticeInformation {
  id: string;
  name: string;
  phone: string;
  email: string;
  web: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface PatientConfirmation {
  patientId: string;
  upcomingAppointmentExists: boolean;
  practice: IPracticeInformation;
}

export const confirmationApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }), // Replace with your API base URL
  endpoints: (builder) => ({
    getPatientConfirmationById: builder.query<PatientConfirmation, string>({
      query: (id) => `patientAppointmentConfirmations/${id}`,
    }),
    setPatientConfirmationById: builder.mutation<PatientConfirmation, string>({
      query: (id) => ({
        url: `patientAppointmentConfirmations/${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetPatientConfirmationByIdQuery,
  useSetPatientConfirmationByIdMutation,
} = confirmationApi;
