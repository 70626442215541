import React from "react";
import { AppBar, Box, Link, Stack, Toolbar, Typography } from "@mui/material";
import { ReactComponent as YapiLogoLight } from "../../assets/footer_yapi_logo_light.svg";

interface IFooterProps {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export const Footer: React.FC<IFooterProps> = ({
  address,
  city,
  state,
  zip,
}) => {
  const href = `https://www.google.com/maps/search/${address}, ${city}, ${state}, ${zip}`;
  return (
    <AppBar
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
        backgroundColor: { xs: "#fff", sm: "#FAE9D4" },
      }}
      elevation={0}
    >
      <Toolbar sx={{ justifyContent: { xs: "center", sm: "space-between" } }}>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" }, // Show on 'xs' and 'sm', hide on 'md' and up
          }}
        >
          <Link
            href={href}
            underline="none"
            color="text.primary"
            target="_blank"
          >
            {address && (
              <Typography variant="caption">
                {address} {city}, {state} {zip}
              </Typography>
            )}
          </Link>
        </Box>
        <Stack direction="row">
          <Typography lineHeight="37px" sx={{ paddingRight: "5px" }}>
            Powered by
          </Typography>
          <YapiLogoLight />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
